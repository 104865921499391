import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../main/utils/i18nConfigProvider';
import StatusDot from './StatusDot';

export const StatusText = props =>
  <>
    {i18n.exists(`issue.status.${props.status?.toLowerCase()?.replace(/ /g,"_")}`) &&
      <>
        {i18n.t(`issue.status.${props.status?.toLowerCase()?.replace(/ /g,"_")}`)}
      </>
    }
    {!i18n.exists(`issue.status.${props.status?.toLowerCase()?.replace(/ /g,"_")}`) &&
      <>
        {props.status}
      </>
    }
  </>;
StatusText.propTypes = {
  status: PropTypes.string
};

export default class Status extends Component {
  render() {
    const color = this.props.color;
    if (this.props.right) {
      return (
        <>
          <span className='mr-2'><StatusText status={this.props.status} /></span>
          <StatusDot color={color}/>
        </>
      );
    }
    else {
      return (
        <>
          <StatusDot color={color}/>
          <span className='ml-2'><StatusText status={this.props.status} /></span>
        </>
      );
    }
  }
}

Status.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
  right: PropTypes.bool
};

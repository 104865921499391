import * as React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { __env } from '../../envloader';
import { getLangTag } from '../utils/contextUtils';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';

function ContextElement(props) {
  const title = props.title;
  const value = props.value;
  if (value && value !== '')
    return (
      <div>
        <Label>{title}: {value}</Label>
        <br/>
      </div>
    );
  else
    return null;
}

ContextElement.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

@withSendRequest
@withTranslation()
export default class ContextView extends React.Component {

  constructor(props) {
    super(props);
    const label_tag = getLangTag();
    this.state = {
      label_tag: label_tag,
      contextConfig: null,
    };
  }

  componentDidMount() {
    this.getContext();
  }

  getContext() {
    const { t } = this.props;
    axiosInstance.get(__env.TICKETS_API_URL + 'api/context/',
      {
        __useAuthorizationHeader: 'never',
        __retryCount: { '500': 1, '503': 1, '400': -1 },
      }).then(response => {
      this.setState({
        contextConfig: response.data.config
      });
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error('tickets-ui', new Error(' Error during get the context config'));
    });
  }

  render() {
    if (this.state.contextConfig && this.props.ticket['context'] && this.props.ticket['context'] !== '') {
      const { t } = this.props;
      let context = this.props.ticket['context'];
      let context1 = this.props.ticket['context1'];
      let context2 = this.props.ticket['context2'];
      let titleContext1 = '';
      let titleContext2 = '';
      const result = this.state.contextConfig.find(({ label_pl }) => label_pl === context);
      if (result && 'selection' in result) {
        context = result[this.state.label_tag];
        titleContext1 = context;
        if (context1 && context1 !== '') {
          const result1 = result['selection']['options'].find(({ label_pl }) => label_pl === context1);
          if (result1 && 'selection' in result1) {
            context1 = result1[this.state.label_tag];
            titleContext2 = context1;
            if (context2 && context2 !== '') {
              const result2 = result1['selection']['options'].find(({ label_pl }) => label_pl === context2);
              if (result2)
                context2 = result2[this.state.label_tag];
            }
          }
          else if (result1 && 'edit' in result1) {
            titleContext2 = result1['edit'][this.state.label_tag];
          }
        }
      }
      else if (result && 'edit' in result) {
        context = result[this.state.label_tag];
        titleContext1 = result['edit'][this.state.label_tag];
        if (result && 'edit2' in result) {
          titleContext2 = result['edit2'][this.state.label_tag];
        }
      }
      return (
        <div>
          <ContextElement title={t('ticket_context')} value={context}/>
          <ContextElement title={titleContext1} value={context1}/>
          <ContextElement title={titleContext2} value={context2}/>
        </div>
      );
    }
    else
      return null;
  }
}

ContextView.propTypes = {
  t: PropTypes.func,
  ticket: PropTypes.object,
  sendRequest: PropTypes.object,
};


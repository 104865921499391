import styled from 'styled-components';

export const SearchForm = styled.form`
  width: 95%;
  position: relative;
  margin-bottom: 24px;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchButton = styled.button`
  border: 0;
  outline: none !important;
  background: ${({ theme }) => theme.colors.background.white};
`;

export const SearchButtonIcon = styled.img`
  margin-left: 9px;
  height: 80%;
`;

export const SearchInputContainer = styled.div`
  border: 1px solid #777676;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3px;
  flex-direction: row;
`;

export const SearchInput = styled.input`
  outline: none !important;
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 100%;
  margin-left: 11.83px;
`;
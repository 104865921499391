import styled from 'styled-components';
import { Card } from 'reactstrap';
import picture from '../../../assets/svg/start/offers.svg';

export const StyledCard = styled(Card)`
  display: flex;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCD6D6;
  box-sizing: border-box;
  border-radius: 20px;

  &:hover {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1), 8px 5px 14px rgba(196, 196, 196, 0.2);
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .icon {
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 90%;
    margin-bottom: 14%;
    background-image: url(${picture});
  }
  
  .picture {
    color: white;
    width: 100% !important;
    height: 100% !important;
    margin-top: 8%;
    margin-left: 7.5%;
    font-size: 1em;
    background-size: 100%;
    background-image: url(${picture});
  }

`;

export const LoginPlacement = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  width: 100%;
`;


export const HelpdeskCards = styled.div`
  max-width: 1440px;
  align-content: center;
  background: #FFFFFF;
  margin-right: auto;
  margin-left: auto;
` ;


export const HelpdeskWelcome = styled.div`
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 68px;
    text-align: center;
    color: #484848;
    margin: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #6A6464;
    margin: 0;
  }
`;


export const HelpdeskTasks = styled.div`
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;  
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.75);
  }
`;

export const SelectionContainer = styled.div`
  max-width: 629px;
  align-content: center;
  background: #FFFFFF;
  margin-right: auto;
  margin-left: auto;
` ;

export const SelectionCategory = styled.h6`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #777676;
  padding-top: 80px;
`;

export const SelectionHeader = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  text-align: center;
  color: #333333;
  margin-top: 16px;
`;

export const SelectionDescription = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #777676;
  margin-top: 32px;
`;

export const CopyrightFooter = styled.div`
  max-width: 1440px;
  height: 40px;
  background: #FFFFFF;
  margin-right: auto;
  margin-left: auto;
  overflow-y: hidden;
` ;

export const InstitutionLogoImage = styled.img`
  height: 80%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 32px;
  margin-right: 32px;
  display: inline-block;
`;

export const FlagLogo = styled.img`
  height: 60%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 32px;
`;

export const InformationLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14.8966px;
  line-height: 19px;
  color: #333333;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 32px;
  padding-left: 1px;
  display: inline-block;
`;

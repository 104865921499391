import * as React from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Card } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import ReCAPTCHA from 'react-google-recaptcha';

import { __env } from '../../envloader/index';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import UploadAttachments from './uploadAttachments';
import Context from './context';


@withSendRequest
@withRouter
@ErrorBoundary(props => props.t('new_ticket_component_name'))
@withTranslation()
export default class NewTicketUnregisteredUser extends React.Component {

  constructor(props) {
    super(props);
    const context = "account";
    const disabled = true;
    this.state = {
      attachments: [],
      context: context,
      disabled: disabled,
      verified: false,
      disabledAddTicket: false
    };
    this.typeChange = this.typeChange.bind(this);
    this.contextChange = this.contextChange.bind(this);
  }

  contextChange(e) {
    this.setState({
      context: e.target.value,
      context1: "",
      context2: ""
    });
  }

  addTicket = () => {
    const { t } = this.props;
    if (!this.email.value) {
      notify.error(t('common:error'), t('new_ticket_error_empty_email'));
      return;
    }
    const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    if (!regEmail.test(this.email.value)) {
      notify.error(t('common:error'), t('new_ticket_error_bad_email'));
      return;
    }
    if (!this.summary.value) {
      notify.error(t('common:error'), t('new_ticket_error_empty_summary'));
      return;
    }
    this.setState({
      disabledAddTicket: true
    });
    let data = new FormData();
    data.append('email', this.email.value);
    data.append('summary', this.summary.value);
    data.append('description', this.description.value);
    if (this.state.type)
      data.append('type', this.state.type);
    for (let attachment of this.state.attachments) {
      data.append(attachment.name, attachment);
    }

    axiosInstance.post(__env.TICKETS_API_URL + "api/create/", data,
      {
        __useAuthorizationHeader: 'never',
        __retryCount: { '500': 1, '503': 1, '400': -1 },
      },).then(response => {
      notify.success(t('success'), t('new_ticket_unregistered_create'));
      this.props.history.push("/");
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during add a new ticket"));
      this.setState({
        disabledAddTicket: false
      });
    });
  };

  attachmentsChange(newAttachments) {
    this.setState({
      attachments: newAttachments
    });
  }

  typeChange(e) {
    this.setState({ type: e.target.value });
  }

  onChange(value) {
    this.setState({ verified: value });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Card>
          <div>
            <h3>{ t('new_ticket') }</h3>
          </div>
          <br/>
          <div className="form-group">
            <label htmlFor="email">{t('new_ticket_email')}:</label>
            <input type="email" className="form-control"
              placeholder={t('new_ticket_unregistered_email')}
              id="email" ref={node => (this.email = node)}/>
          </div>
          <Context context={this.state.context}
            context1={this.state.context1}
            context2={this.state.context2}
            disabled={this.state.disabled}
          />
          <FormGroup>
            <Label for="type">{ t('new_ticket_type') }:</Label>
            <Input type="select" name="type" id="type" value={this.state.type} onChange={this.typeChange}>
              <option value="">{ t('new_ticket_type_select') }</option>
              <option value="Problem">{ t('ticket_type_Problem') }</option>
              <option value="Zapytanie">{ t('ticket_type_Zapytanie') }</option>
              <option value="Inne">{ t('ticket_type_Inne') }</option>
            </Input>
          </FormGroup>
          <div className="form-group">
            <label htmlFor="summary">{t('ticket_summary')}:</label>
            <input type="text" className="form-control"
              placeholder={t('new_ticket_summary_overview')}
              id="summary" ref={node => (this.summary = node)}/>
          </div>
          <div className="form-group">
            <label htmlFor="description">{t('ticket_description')}:</label>
            <textarea className="form-control" rows="8" placeholder={t('new_ticket_description_overview')}
              id="description" ref={node => (this.description = node)}/>
            <br/>
            <UploadAttachments attachments={this.state.attachments}
              onAttachmentsChange={ this.attachmentsChange.bind(this) }
            />
          </div>
          <div className="d-flex flex-row-reverse mb-2">
            <ReCAPTCHA
              sitekey={ __env.RECAPTCHA_SITE_KEY }
              onChange={this.onChange.bind(this)}
            />
          </div>
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <Button id="add-ticket" color="primary" onClick={this.addTicket} disabled={!this.state.verified || this.state.disabledAddTicket}>
                {t('new_ticket_add')}
              </Button>
            </div>
            <div className="p-2">
              <a className='btn btn-outline-secondary mr-4' href={'/'} >
                {t('cancel')}
              </a>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

NewTicketUnregisteredUser.propTypes = {
  sendRequest: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};


import * as React from 'react';
import PropTypes from 'prop-types';

import Attachment from './attachment';

export default function Attachments(props) {
  const attachments = props.attachments;
  if (attachments.length) {
    return (
      <div>
        {attachments.map((attachment) =>
          <Attachment name={ attachment['name'] } id={ attachment['id'] } comment_id={ attachment['comment_id'] }
            ticket_id={ props.ticket_id } key={ attachment['comment_id'] + ' ' + attachment['id']}/>
        )}
      </div>
    );
  }
  else {
    return null;
  }
}

Attachments.propTypes = {
  attachments: PropTypes.array,
  comment_id: PropTypes.string,
  ticket_id: PropTypes.string,
};


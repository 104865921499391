import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withTranslation()
export default class User extends Component {
  render() {
    const { t } = this.props;
    let dropzoneStyle = {
      height: '44px',
      width: '44px',
      backgroundColor: '#106BEF',
      borderRadius: '50%',
      display: 'inline-block',
      border: 'none !important',
      outline: 'none !important',
      outlineWidth: '0px',
      borderWidth: '0px',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      after: {
        border: 'none'
      }
    };
    const username = this.props.loginStore.get('name');
    const initial = username.charAt(0).toUpperCase();
    return (
      <Dropdown style={dropzoneStyle} isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle style={dropzoneStyle} >{initial}</DropdownToggle>
        <DropdownMenu right>
          {this.props.profileRedirect && <DropdownItem href={this.props.profileRedirect}>{t('common:account_settings')}</DropdownItem>}
          <DropdownItem href="#" onClick={this.props.logOut}>{t('common:logout')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

User.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  profileRedirect: PropTypes.string,
  logOut: PropTypes.func,
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object //HOC
};

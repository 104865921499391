import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { HelpdeskCards, SelectionCategory, SelectionContainer, SelectionDescription, SelectionHeader } from '../styles/styled-components/welcomePage';
import ContextCard from './ContextCard';
import OffersImage from '../../assets/svg/start/offers.svg';
import ServicesImage from '../../assets/svg/start/services.svg';
import BillingsImage from '../../assets/svg/start/billings.svg';
import PortalImage from '../../assets/svg/start/portal.svg';
import AccountImage from '../../assets/svg/start/account.svg';
import OtherImage from '../../assets/svg/start/other.svg';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withTranslation()
export default class LandingPageCenter extends Component {
  render() {
    const { t } = this.props;
    if (this.props.loginStore) {
      return (
        <HelpdeskCards>
          <SelectionContainer>
            <SelectionCategory>{t('welcome.center.category')}</SelectionCategory>
            <SelectionHeader>{t('welcome.center.header')}</SelectionHeader>
            <SelectionDescription>{t('welcome.center.description')}</SelectionDescription>
          </SelectionContainer>
          <Container>
            <Row className='grid-row'>
              <ContextCard image={OffersImage} card={'offer'}/>
              <ContextCard image={ServicesImage} card={'service'}/>
              <ContextCard image={BillingsImage} card={'billing'}/>
              <ContextCard image={PortalImage} card={'portal'}/>
              <ContextCard image={AccountImage} card={'account'}/>
              <ContextCard image={OtherImage} card={'other'}/>
            </Row>
          </Container>
        </HelpdeskCards>
      );
    }
    else {
      return null;
    }
  }
}

LandingPageCenter.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
};

import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 19px 19px;
  border-radius: 24px !important;
  align-items: center;
  height: 36px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  outline: none !important;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

import * as React from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Card } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { __env } from '../../envloader/index';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import UploadAttachments from './uploadAttachments';
import Context from './context';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withSendRequest
@withRouter
@ErrorBoundary(props => props.t('new_ticket_component_name'))
@withTranslation()
export default class NewTicketView extends React.Component {

  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    const context = url.searchParams.get('context');
    const context1 = url.searchParams.get('context1');
    const context2 = url.searchParams.get('context2');
    const contextAdm = url.searchParams.get('contextAdm');
    const edit = url.searchParams.get('edit');
    const type = url.searchParams.get('type');
    let disabled = context != null;
    if (edit != null)
      disabled = false;
    this.state = {
      attachments: [],
      type: type,
      context: context,
      context1: context1,
      context2: context2,
      contextAdm: contextAdm,
      disabled: disabled,
      disabledAddTicket: false
    };
    this.typeChange = this.typeChange.bind(this);
    this.contextChange = this.contextChange.bind(this);
    this.context1Change = this.context1Change.bind(this);
    this.context2Change = this.context2Change.bind(this);
  }

  addTicket = () => {
    const { t } = this.props;
    if (!this.summary.value) {
      notify.error(t('common:error'), t('new_ticket_error_empty_summary'));
      return;
    }
    this.setState({
      disabledAddTicket: true
    });
    let data = new FormData();
    data.append('summary', this.summary.value);
    data.append('description', this.description.value);
    if (this.state.type)
      data.append('type', this.state.type);
    data.append('context', this.state.context);
    data.append('context1', this.state.context1);
    data.append('context2', this.state.context2);
    data.append('contextAdm', this.state.contextAdm);
    for (let attachment of this.state.attachments) {
      data.append(attachment.name, attachment);
    }
    this.props.sendRequest('post',
      __env.TICKETS_API_URL + "api/tickets/", data).then(response => {
      notify.success(t('success'), t('new_ticket_create_success') + response.data['key']);
      if (this.props.reloadTickets)
        this.props.reloadTickets();
      this.props.history.push("/tickets");
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during add a new ticket"));
      this.setState({
        disabledAddTicket: false
      });
    });
  };

  attachmentsChange(newAttachments) {
    this.setState({
      attachments: newAttachments
    });
  }

  typeChange(e) {
    this.setState({ type: e.target.value });
  }

  contextChange(e) {
    this.setState({
      context: e.target.value,
      context1: "",
      context2: ""
    });
  }

  context1Change(e) {
    this.setState({
      context1: e.target.value,
      context2: ""
    });
  }

  context2Change(e) {
    this.setState({ context2: e.target.value });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Card>
          <div>
            <h3>{ t('new_ticket') }</h3>
          </div>
          <br/>
          <Context context={this.state.context} contextChanged={this.contextChange}
            context1={this.state.context1} context1Changed={this.context1Change}
            context2={this.state.context2} context2Changed={this.context2Change}
            disabled={this.state.disabled}
          />
          <FormGroup>
            <Label for="type">{ t('new_ticket_type') }:</Label>
            <Input type="select" name="type" id="type" value={this.state.type} onChange={this.typeChange}>
              <option value="">{ t('new_ticket_type_select') }</option>
              <option value="Problem">{ t('ticket_type_Problem') }</option>
              <option value="Zapytanie">{ t('ticket_type_Zapytanie') }</option>
              <option value="Inne">{ t('ticket_type_Inne') }</option>
            </Input>
          </FormGroup>
          <div className="form-group">
            <label htmlFor="summary">{t('ticket_summary')}:</label>
            <input type="text" className="form-control"
              placeholder={t('new_ticket_summary_overview')}
              id="summary" ref={node => (this.summary = node)}/>
          </div>
          <div className="form-group">
            <label htmlFor="description">{t('ticket_description')}:</label>
            <textarea className="form-control" rows="8" placeholder={t('new_ticket_description_overview')}
              id="description" ref={node => (this.description = node)}/>
            <br/>
            <UploadAttachments attachments={this.state.attachments}
              onAttachmentsChange={ this.attachmentsChange.bind(this) }
            />
          </div>
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <Button id="add-ticket" color="primary" onClick={this.addTicket} disabled={this.state.disabledAddTicket}>
                {t('new_ticket_add')}
              </Button>
            </div>
            <div className="p-2">
              <a className='btn btn-outline-secondary mr-4' href={'/tickets'} >
                {t('cancel')}
              </a>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

NewTicketView.propTypes = {
  sendRequest: PropTypes.object,
  t: PropTypes.func,
  reloadTickets: PropTypes.func,
  loginStore: PropTypes.object, //HOC
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};


import * as React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { getContext } from '../actions';
import { __env } from '../../envloader';
import { getLangTag } from '../utils/contextUtils';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import axiosInstance from '../../main/utils/axios/axiosInstance';

function ContextSelect(props) {
  const title = props.title;
  const options = props.options;
  const value = props.value;
  const valueChanged = props.valueChanged;
  const lang = props.lang;
  const id = props.id;
  const disabled = props.disabled;
  if (props.visible)
    return (
      <FormGroup>
        <Label for={ id }>{title}:</Label>
        <Input type="select" name={ id } id={ id } value={ value } onChange={valueChanged} role="select-option" aria-hidden="true" disabled={disabled}>
          {options.map((option, index) => (
            <option value={option.key} key={index}>{option[lang]}</option>
          ))}
        </Input>
      </FormGroup>
    );
  else
    return null;
}

ContextSelect.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  lang: PropTypes.string,
  valueChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

function ContextEdit(props) {
  const title = props.title;
  const value = props.value;
  const valueChanged = props.valueChanged;
  const id = props.id;
  const disabled = props.disabled;
  if (props.visible)
    return (
      <FormGroup>
        <Label for={ id }>{ title }:</Label>
        <Input type="text" name={ id } id={ id } value={value} onChange={valueChanged} disabled={disabled}/>
      </FormGroup>
    );
  else
    return null;
}

ContextEdit.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  valueChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

@connect(
  state => ({
    contextConfig: state.contextConfig,
  }),
  dispatch => {
    return bindActionCreators({
      getContext: getContext,
    }, dispatch);
  }
)
@withSendRequest
@withTranslation()
@ErrorBoundary(props => props.t('context_component_name'))
export default class Context extends React.Component {

  constructor(props) {
    super(props);
    const { t } = this.props;
    const label_tag = getLangTag();
    this.state = {
      context_select: true,
      context_title: t('ticket_context'),
      context1select: false,
      context1edit: false,
      context1_title: "ss",
      context2select: false,
      context2edit: false,
      context2_title: "",
      context2_options: [],
      label_tag: label_tag,
      contextConfig: null
    };
    this.contextChange = this.contextChange.bind(this);
    this.context1Change = this.context1Change.bind(this);
    this.context2Change = this.context2Change.bind(this);
  }

  componentDidMount() {
    this.getContext();
  }

  getContext() {
    const { t } = this.props;
    axiosInstance.get(__env.TICKETS_API_URL + 'api/context/',
      {
        __useAuthorizationHeader: 'never',
        __retryCount: { '500': 1, '503': 1, '400': -1 },
      }).then(response => {
      this.setState({
        contextConfig: response.data.config,
      });
      this.contextChangeSetStates(this.props.context);
      this.context1SetStates(this.props.context1);
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error('tickets-ui', new Error(t('context.error_get')));
    });
  }

  contextChangeSetStates(tag) {
    const result = this.state.contextConfig.find(({ key }) => key === tag);
    if (result) {
      if ("selection" in result) {
        this.setState({
          context1select: true,
          context1edit: false,
          context1_title: result['selection'][this.state.label_tag],
          context1_options: result['selection']['options'],
          context2select: false,
          context2edit: false,
        });
      }
      else if ("edit" in result) {
        let c2edit = false;
        let c2title = "";
        if ("edit2" in result) {
          c2edit = true;
          c2title = result['edit2'][this.state.label_tag];
        }
        this.setState({
          context1select: false,
          context1edit: true,
          context1_title: result['edit'][this.state.label_tag],
          context2select: false,
          context2edit: c2edit,
          context2_title: c2title,
        });
      }
      else {
        this.setState({
          context1select: false,
          context1edit: false,
          context2select: false,
          context2edit: false,
        });
      }
    }
  }

  contextChange(e) {
    const tag = e.target.value;
    this.contextChangeSetStates(tag);
    if (this.props.contextChanged) {
      this.props.contextChanged(e);
    }
  }

  context1SetStates(tag) {
    if (this.state.context1select) {
      const c1 = this.state.contextConfig.find(({ key }) => key === this.props.context);
      const result = c1['selection']['options'].find(({ key }) => key === tag);
      if (result) {
        if ("selection" in result) {
          this.setState({
            context2_title: result['selection'][this.state.label_tag],
            context2_options: result['selection']['options'],
            context2select: true,
            context2edit: false,
          });
        }
        else if ("edit" in result) {
          this.setState({
            context2_title: result['edit'][this.state.label_tag],
            context2select: false,
            context2edit: true,
          });
        }
        else {
          this.setState({
            context2select: false,
            context2edit: false,
          });
        }
      }
    }
  }

  context1Change(e) {
    const tag = e.target.value;
    this.context1SetStates(tag);
    if (this.props.context1Changed) {
      this.props.context1Changed(e);
    }
  }

  context2Change(e) {
    if (this.props.context2Changed) {
      this.props.context2Changed(e);
    }
  }

  render() {
    if (this.state.contextConfig) {
      return (
        <div>
          <ContextSelect visible={this.state.context_select} title={this.state.context_title}
            id={"contextselect"} lang={this.state.label_tag} disabled={this.props.disabled}
            options={this.state.contextConfig} value={this.props.context} valueChanged={this.contextChange}/>
          <ContextSelect visible={this.state.context1select} title={this.state.context1_title}
            id={"context1select"} lang={this.state.label_tag} disabled={this.props.disabled}
            options={this.state.context1_options} value={this.props.context1} valueChanged={this.context1Change}/>
          <ContextEdit visible={this.state.context1edit} title={this.state.context1_title}
            id={"context1edit"} disabled={this.props.disabled}
            value={this.props.context1} valueChanged={this.context1Change}/>
          <ContextSelect visible={this.state.context2select} title={this.state.context2_title}
            id={"context2select"} lang={this.state.label_tag} disabled={this.props.disabled}
            options={this.state.context2_options} value={this.props.context2}
            valueChanged={this.context2Change}/>
          <ContextEdit visible={this.state.context2edit} title={this.state.context2_title}
            id={"context2edit"} disabled={this.props.disabled}
            value={this.props.context2} valueChanged={this.context2Change}/>
        </div>
      );
    }
    else
      return null;
  }
}

Context.propTypes = {
  t: PropTypes.func,
  sendRequest: PropTypes.object,
  context: PropTypes.string,
  contextChanged: PropTypes.func,
  context1: PropTypes.string,
  context1Changed: PropTypes.func,
  context2: PropTypes.string,
  context2Changed: PropTypes.func,
  contextConfig: PropTypes.object,
  getContext: PropTypes.func,
  disabled: PropTypes.bool,
};

import styled from 'styled-components';
import attachmentIcon from '../../../assets/svg/icons/attachment.svg';
import uploadIcon from '../../../assets/svg/icons/upload.svg';
import uploadHoverIcon from '../../../assets/svg/icons/upload_hover.svg';
import { Label } from 'reactstrap';

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none !important;
  display: inline-flex;
  align-items: center;
  margin-left: 50px;
  margin-right: -10px;
`;



export const UploadedFile = styled(Label)`
  display: inline-flex;
  margin-top: 5px;
  background: #FFFFFF;
  border: 1px solid #777676;
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 7px 16px;


  &:hover {
    border: 1px solid ${(props) => props.theme.colors.black};

    .icon {
      background-image: url(${attachmentIcon});
    }
    .text {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    color: ${(props) => props.theme.colors.text.darkGrey};
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.grey4};
    font-size: ${(props) => props.theme.fontSizes.lg};
    width: 20px;
  }

  .icon {
    width: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding: 0;
    padding-bottom: 92%;
    background-image: url(${attachmentIcon});
  }
`;

// affilations container
export const Upload = styled.div`
  display: flex;
  flex-direction: column;

  .upload-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }  
`;


export const UploadFile = styled(Label)`
  display: inline-flex;
  margin-top: 5px;
  background: #FFFFFF;
  border: 1px solid #106BEF;
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 7px 16px;
  cursor: pointer;
  
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.black};
    background: #106BEF;

    .icon {
      background-image: url(${uploadHoverIcon});
    }
    .text {
      color: #FFFFFF;
    }
  }

  .text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    color: #106BEF;
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.grey4};
    font-size: ${(props) => props.theme.fontSizes.lg};
    width: 20px;
  }

  .icon {
    width: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding: 0;
    padding-bottom: 92%;
    background-image: url(${uploadIcon});
  }
`;

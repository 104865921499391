import i18n from 'i18next';

const getCurrentLang = () => {
  return (i18n.language?.startsWith('pl') || window.localStorage.i18nextLng?.startsWith('pl')) ? 'pl' : 'en';
};

export const getLangTag = () => {
  if (getCurrentLang().substring(0, 2) === 'pl') {
    return 'label_pl';
  }
  return 'label_en';
};

export default getCurrentLang;

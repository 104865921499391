import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DeleteIcon from '../../assets/svg/icons/delete.svg';
import { DeleteButton, Upload, UploadedFile, UploadFile } from '../styles/styled-components/UploadedFile';
import * as notify from '../../main/utils/notify';
import { __env } from '../../envloader/index';

const File = ({ name, onClick, index, ...props }) => (
  <div>
    <UploadedFile {...props} >
      <div className='icon-wrapper'>
        <div className='icon' />
      </div>
      <div className='text'>{name}</div>
      <DeleteButton  onClick={((e) => onClick(e, index))}>
        <img src={DeleteIcon} alt='delete icon'/>
      </DeleteButton>
    </UploadedFile>
  </div>
);

File.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.number
};


const UploadAttachment = ({ onChange, description, ...props }) => (
  <div>
    <input type="file" className="upload-file" name="attachment" id="attachment" onChange={ onChange } />
    <UploadFile htmlFor="attachment" {...props} >
      <div className='icon-wrapper'>
        <div className='icon' />
      </div>
      <div className='text'>{ description }</div>
    </UploadFile>
  </div>
);

UploadAttachment.propTypes = {
  onChange: PropTypes.func,
  description: PropTypes.string
};


@withTranslation()
export default class UploadAttachments extends React.Component {

  addAttachment = (e) => {
    const { t } = this.props;
    if (e.target.files && e.target.files[0]) {
      // const MAX_ATTACHMENT_SIZE = 20;
      if (e.target.files[0].size > __env.MAX_ATTACHMENT_SIZE) {
        notify.warning(t('common:error'), t('attachment.warning_size_exceeded'));
      }
      else {
        const attachments = [ ...this.props.attachments, e.target.files[0] ];
        this.props.onAttachmentsChange(attachments);
      }
    }
  };

  deleteAttachment = (e, index) => {
    let attachments = Array.from(this.props.attachments);
    attachments.splice(index, 1);
    this.props.onAttachmentsChange(attachments);
  };

  render() {
    const { t } = this.props;
    const attachments = this.props.attachments;
    const uploadText = this.props.attachments.length > 0 ? t('attachment.attach_another_file') : t('attachment.attach_file');
    return (
      <Upload>
        {attachments.map((attachment, index) =>
          <File name={attachment.name} key={index} index={ index } onClick={ this.deleteAttachment } />
        )}
        <UploadAttachment onChange={ this.addAttachment } description={ uploadText }/>
      </Upload>
    );
  }
}


UploadAttachments.propTypes = {
  attachments: PropTypes.array,
  onAttachmentsChange: PropTypes.func,
  t: PropTypes.func,
};


import * as React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import pl from 'javascript-time-ago/locale/pl';

import Attachments from '../containers/attachments';
import UserAvatar from '../components/UserAvatar';
import getCurrentLang from '../utils/contextUtils';
import { CommentSpan, DateSpan, StyledCard, StyledHr } from '../styles/styled-components/Comment';
import TinyMCE from '../utils/TinyMCE';

function CommentAttachments(props) {
  const attachments_ = props.attachments;
  let attachments = [];
  for (let a of attachments_) {
    if (a['comment_id'] === props.comment_id)
      attachments.push(a);
  }
  return (
    <Attachments attachments={attachments} comment_id={props.comment_id} ticket_id={props.ticket_id}/>
  );
}

CommentAttachments.propTypes = {
  attachments: PropTypes.array,
  comment_id: PropTypes.string,
  ticket_id: PropTypes.string,
};

function Comment(props) {
  const date = new Date(props.created);
  let timeAgo;
  if (getCurrentLang() === 'pl') {
    TimeAgo.addLocale(pl);
    timeAgo = new TimeAgo('pl-PL');
  }
  else {
    TimeAgo.addLocale(en);
    timeAgo = new TimeAgo('en-US');
  }
  const period = timeAgo.format(date);
  const t = props.t;
  return (
    <StyledCard>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <UserAvatar username={props.author}/>
          <span>{t('user')} <b>{props.author}</b> {t('comments_added_comment')}</span>
        </div>
        <DateSpan title={date.toLocaleString()}>{period}</DateSpan>
      </div>
      <StyledHr/>
      <div className="d-flex flex-column">
        <CommentSpan>Komentarz:</CommentSpan>
        <p>
          <TinyMCE value={props.body} disabled={true} />
        </p>
      </div>
    </StyledCard>
  );
}

Comment.propTypes = {
  created: PropTypes.string,
  body: PropTypes.string,
  author: PropTypes.string,
  t: PropTypes.func,
  attachments: PropTypes.array,
  id: PropTypes.string,
  ticket_id: PropTypes.string,
  index: PropTypes.number,
};

export default Comment;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StatusDot extends Component {
  render() {
    let dropzoneStyle = {
      height: '8px',
      width: '8px',
      backgroundColor: this.props.color,
      borderRadius: '50%',
      border: 'none',
      display: 'inline-block'
    };

    return (
      <span style={dropzoneStyle}></span>
    );
  }
}

StatusDot.propTypes = {
  color: PropTypes.string,
};

import styled from 'styled-components';
import attachmentIcon from '../../../assets/svg/icons/attachment.svg';
import uploadHoverIcon from '../../../assets/svg/icons/upload_hover.svg';

export const FileButton = styled.button`
  display: flex;
  margin-top: 5px;
  background: #FFFFFF;
  border: 1px solid #777676;
  box-sizing: border-box;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;


  &:hover {
    border: 1px solid ${(props) => props.theme.colors.black};
    background: #106BEF;

    .icon {
      background-image: url(${uploadHoverIcon});
    }
    .text {
      color: #FFFFFF;
    }
  }

  .text {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: ${(props) => props.theme.fontWeights.medium};
    color: ${(props) => props.theme.colors.text.darkGrey};
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.grey4};
    font-size: ${(props) => props.theme.fontSizes.lg};
    width: 20px;
  }

  .icon {
    width: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding: 0;
    padding-bottom: 92%;
    background-image: url(${attachmentIcon});
  }
`;

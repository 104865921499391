import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { StyledButton } from '../../tickets/styles/styled-components/Button';
import User from '../../tickets/components/User';

import { checkIfLoginIsPossible, getProfileRedirect } from '../utils/authUtils';
import { logIn, logOut } from '../../oidc-middleware/actions/login';
import Loading from '../components/loading';


@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  }),
  dispatch => {
    return bindActionCreators({
      logIn: logIn,
      logOut: logOut,
    }, dispatch);
  }
)
export default class Login extends Component {
  state = {
    dropdownOpen: false,
    profileRedirect: null,
  };
  _isMounted= false;

  componentDidMount() {
    this._isMounted = true;
    if (checkIfLoginIsPossible())
      getProfileRedirect().then(value => {
        if (this._isMounted)
          this.setState({ profileRedirect: value });
      });
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  dropdownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  renderLoginBar() {
    const { t } = this.props;
    const throttledLogin = _.throttle(this.props.logIn, 1000);

    if (!checkIfLoginIsPossible()) {
      //Login is impossible
      return null;
    }

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading size="sm" />;
    }

    if (!this.props.loginStore) {
      //user is not logged in
      return (
        <NavItem>
          <NavLink
            id='login'
            href="#"
            onClick={() => throttledLogin()}
            tag={StyledButton}
            className='login-button'
          >
            <span className="ml-2 mr-2">{t('common:login')}</span>
          </NavLink>
        </NavItem>
      );
    }

    //user in logged in
    return (
      <User isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle} profileRedirect={this.state.profileRedirect} logOut={this.props.logOut}>
      </User>
    );
  }

  renderButton() {
    const { t } = this.props;
    const throttledLogin = _.throttle(this.props.logIn, 1000);

    if (!checkIfLoginIsPossible()) {
      //Login is impossible
      return null;
    }

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading size="sm" />;
    }

    if (!this.props.loginStore) {
      return (
        <StyledButton
          id='login_button'
          onClick={() => throttledLogin()}
        >
          {t('common:login')}
        </StyledButton>
      );
    }
    return null;
  }

  render() {
    if (this.props.loginBar) {
      return (
        this.renderLoginBar()
      );
    }
    else
      return (
        this.renderButton()
      );
  }
}

Login.propTypes = {
  logIn: PropTypes.func, //HOC
  logOut: PropTypes.func, //HOC
  location: PropTypes.object,
  t: PropTypes.func, //HOC,
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
  loginBar: PropTypes.bool,
};

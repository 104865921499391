import styled from 'styled-components';
import { Card } from 'reactstrap';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  width: 760px;
  min-height: 316px;
  background: #FFFFFF;
  border: 1px solid #DCD6D6;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0;
  padding: 30px 56px;
  gap: 56px;

  &:hover {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1), 8px 5px 14px rgba(196, 196, 196, 0.2);
  }
`;

export const AccountHeader = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  text-align: left;
  color: #585858;
  padding-top: 63px;
`;

export const AccountDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #585858;
  margin-top: 20px;
  margin-bottom: 26px;
`;

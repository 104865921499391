import { GET_TICKETS, GET_TICKET, GET_CONTEXT } from '../actions';

export const ticketReducer = (state = [], action) => {
  switch (action.type) {

  case GET_TICKETS:
    return action.payload;

  default:
    return state;
  }
};

export const ticket1Reducer = (state = [], action) => {
  switch (action.type) {

  case GET_TICKET:
    return action.payload;

  default:
    return state;
  }
};

export const contextReducer = (state = [], action) => {
  switch (action.type) {

  case GET_CONTEXT:
    return action.payload;

  default:
    return state;
  }
};

import styled from 'styled-components';
import { Card } from 'reactstrap';

export const StyledCard = styled(Card)`
  display: flex;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCD6D6;
  box-sizing: border-box;
  border-radius: 20px;
  margin-left: 0;
  padding-top: 20px;
  padding-bottom: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;


export const CommentSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
`;


export const DateSpan = styled.span`
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #777676;`;



export const StyledHr = styled.hr`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
`;

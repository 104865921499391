import React from 'react';
import { Spinner } from 'reactstrap';

const CommonLoader = () => (
  <div className="text-center w-100 common-loader">
    <Spinner
      as="span"
      animation="border"
      role="status"
    />
  </div>
);
export default CommonLoader;

import React from 'react';
import PropTypes from 'prop-types';

import { FileButton } from '../styles/styled-components/FileButton';

const AttachmentButton = ({ fileName, onClick, ...props }) => (
  <FileButton onClick={onClick} {...props} >
    <div className='icon-wrapper'>
      <div className='icon' />
    </div>
    <div className='text'>{fileName}</div>
  </FileButton>
);

AttachmentButton.propTypes = {
  onClick: PropTypes.func,
  fileName: PropTypes.string
};

export default AttachmentButton;
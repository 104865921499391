import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import TopBar from '../../tickets/containers/topbar';
import TicketsList from '../../tickets/containers/ticketsList';
import Main from '../../tickets/containers/main';
import EditTicketView from '../../tickets/containers/editTicketView';
import NewTicketView from '../../tickets/containers/newTicketView';
import '../../tickets/styles/index.scss';
import NewTicketUnregisteredUser from '../../tickets/containers/newTicketUnregisteredUser';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={Main} />
          <Route exact strict path='/tickets' component={Main} />
          <Route exact strict path="/newTicketUnregistered" component={NewTicketUnregisteredUser}/>
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <Switch>
          <Route exact strict path='/' component={Main} />
          <Route exact strict path="/newTicket" component={NewTicketView}/>
          <Route exact strict path="/newTicketUnregistered" component={NewTicketUnregisteredUser}/>
          <Route exact strict path="/ticket/:ticketId" component={EditTicketView}/>
          <Route exact strict path='/tickets' component={TicketsList} />
          <Route component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    return (
      <div>
        <TopBar />
        {this.renderContent()}
      </div>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import Pagination from 'react-bootstrap/Pagination';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

const DOTS = '...';

export class TablePagination extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeStatusDropDownValue = (e) => {
    let pageSize = e.currentTarget.getAttribute("dropdownvalue");
    if (pageSize !== this.props.pageSize)
      this.props.onPageChange(this.props.currentPage, pageSize);
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  getPagesRagnge() {
    const pageSize = this.props.pageSize;
    const totalCount = this.props.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = this.props.currentPage;
    const siblingCount = 1;
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return this.range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPages
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = this.range(1, leftItemCount);

      return [ ...leftRange, DOTS, totalPages ];
    }

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = this.range(1, leftItemCount);

      return [ ...leftRange, DOTS, totalPages ];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {

      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = this.range(
        totalPages - rightItemCount + 1,
        totalPages
      );
      return [ firstPageIndex, DOTS, ...rightRange ];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = this.range(leftSiblingIndex, rightSiblingIndex);
      return [ firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex ];
    }
    return null;
  }

  render() {
    const { t } = this.props;
    const pageSize = this.props.pageSize;
    const totalCount = this.props.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = this.props.currentPage;
    const items = [];
    const pagesRange = this.getPagesRagnge();

    if (totalPages === 1) {
      items.push(
        <Pagination.Item key={1} active={currentPage === 1}>
          {1}
        </Pagination.Item>
      );
    }
    if (totalPages > 1) {
      items.push(
        <Pagination.Prev key="prev" className='edge' disabled={currentPage === 1} onClick={() => this.props.onPageChange(currentPage - 1, pageSize)}>
          {"<"}
        </Pagination.Prev>
      );

      const pagesItems = pagesRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <Pagination.Item key={0} disabled>
                            &#8230;
            </Pagination.Item>
          );
        }
        else {
          return (
            <Pagination.Item key={pageNumber} active={currentPage === pageNumber}
              onClick={() => this.props.onPageChange(pageNumber, pageSize)}>
              {pageNumber}
            </Pagination.Item>
          );
        }
      });
      items.push(pagesItems);

      items.push(
        <Pagination.Next key="next" disabled={currentPage === totalPages}
          onClick={() => this.props.onPageChange(currentPage + 1, pageSize)}>
          {">"}
        </Pagination.Next>
      );
    }
    return (
      <div className="d-flex justify-content-between">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret className="dropdown-toggle">
            {this.props.pageSize}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={ this.changeStatusDropDownValue } dropdownvalue={ 5 } key={5}>{ 5 }</DropdownItem>
            <DropdownItem onClick={ this.changeStatusDropDownValue } dropdownvalue={ 10 } key={10}>{ 10 }</DropdownItem>
            <DropdownItem onClick={ this.changeStatusDropDownValue } dropdownvalue={ 20 } key={20}>{ 20 }</DropdownItem>
            <DropdownItem onClick={ this.changeStatusDropDownValue } dropdownvalue={ 50 } key={50}>{ 50 }</DropdownItem>
            <DropdownItem onClick={ this.changeStatusDropDownValue } dropdownvalue={ totalCount } key={ totalCount }>{ t('ticket_list_all') }</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Pagination size="sm" className="flex-grow-1 justify-content-center">
          {items}
        </Pagination>
      </div>
    );
  }
}

TablePagination.propTypes = {
  t: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onPageChange: PropTypes.func,
};

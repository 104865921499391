import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { __env } from '../../envloader/index';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import { getLogger } from '../../main/utils/logger';
import UploadAttachments from './uploadAttachments';


@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withSendRequest
@ErrorBoundary(props => props.t('add_comment_component_name'))
@withTranslation()
export default class AddComment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
    };
  }

  addComment = () => {
    const { t } = this.props;
    if (!this.comment.value) {
      notify.error(t('common:error'), t('add_comment_empty'));
      return;
    }
    const data = new FormData();
    data.append('comment', this.comment.value);
    for (let file of this.state.attachments) {
      data.append(file.name, file);
    }
    const url = __env.TICKETS_API_URL + "api/tickets/" + this.props.ticketId + '/';
    this.props.sendRequest('patch', url, data).then(response => {
      this.close();
      notify.success(t('success'), t('edit_ticket_add_comment_success'));
    }).catch(error => {
      notify.error(t('common:error'), t('try_later'));
      getLogger().error("tickets-ui", new Error(" Error during add comment to the ticket"));
      this.props.onModalChange();
    });
  };

  close = () => {
    if (this.props.reloadTickets)
      this.props.reloadTickets();
    if (this.props.reloadTicket)
      this.props.reloadTicket();
    this.props.onModalChange();
    const attachments = [];
    this.attachmentsChange(attachments);
  };

  attachmentsChange(newAttachments) {
    this.setState({
      attachments: newAttachments
    });
  }


  render() {
    const { t } = this.props;
    return (
      <div>
        <Modal isOpen={this.props.modal} toggle={this.props.onModalChange} size="lg" >
          <ModalHeader toggle={this.props.onModalChange}><b>{ t('add_comment_title') } { this.props.ticket['key'] }</b></ModalHeader>
          <ModalBody>
            <div>
              <div className="form-group">
                <label htmlFor="comment">{ t('add_comment_comment') }:</label>
                <textarea className="form-control" rows="8" placeholder={ t('add_comment_overview') }
                  id="comment" ref={ node => (this.comment = node) }
                />
                <br/>
                <UploadAttachments attachments={this.state.attachments}
                  onAttachmentsChange={ this.attachmentsChange.bind(this) }
                />
                <br/>
              </div>
              <br/>
              <Button id="add-comment" color="primary" onClick={this.addComment} >
                { t('add_comment_action') }
              </Button>
              {' '}
              <Button color="secondary" onClick={this.close} >
                { t('cancel') }
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}


AddComment.propTypes = {
  reloadTickets: PropTypes.func,
  sendRequest: PropTypes.func,
  t: PropTypes.func,
  loginStore: PropTypes.object, //HOC
  onModalChange: PropTypes.func,
  ticketId: PropTypes.number,
  ticket: PropTypes.object,
  modal: PropTypes.bool,
  reloadTicket: PropTypes.func,
};


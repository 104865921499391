import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import Root from './containers/root';
import configureStore from './stores/configureStore';
import ThemeProvider from '../tickets/styles/styled-components/Theme';
import { AxiosInstancesContext } from './hoc/utils/axiosContext';
import { axiosInstances } from './hoc/utils/axiosUtils';
import { injectStore } from './utils/axios/axiosInstance';

export const { history, store } = configureStore();

injectStore(store);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div data-testid='app-test'>
        <Suspense fallback={<div></div>}>
          <ThemeProvider>
            <AxiosInstancesContext.Provider value={axiosInstances}>
              <Root />
            </AxiosInstancesContext.Provider>
          </ThemeProvider>
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            removeOnHover={false}
            removeOnHoverTimeOut={10000}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            //progressBar
            closeOnToastrClick={false}
          />
        </Suspense>
      </div>
    </ConnectedRouter>
  </Provider>
);

export default App;

import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = () => (
  <div className="text-center w-100">
    <Spinner
      as="span"
      animation="border"
      role="status"
      className="m-5"
    />
  </div>
);
export default Loader;

import styled from 'styled-components';

export const TicketsHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.text.black};
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const Th = styled.th`
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.text.grey3};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Td = styled.td`
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.text.grey5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.grey5};
`;

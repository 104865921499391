import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UserAvatarIcon } from '../styles/styled-components/UserAvatar';

export default class UserAvatar extends Component {
  render() {
    return (
      <UserAvatarIcon />
    );
  }
}

UserAvatar.propTypes = {
  username: PropTypes.string
};

import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import { __env } from '../../envloader';
import { getLogger } from '../../main/utils/logger';
import AttachmentButton from './AttachmentButton';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
  }),
)
@withSendRequest
@ErrorBoundary(props => props.t('attachment_component_name'))
@withTranslation()
export default class Attachment extends React.Component {

  getAttachment = () => {
    const { t } = this.props;
    const name = this.props.name;
    const id = this.props.id;
    const comment_id = this.props.comment_id;
    const ticket_id = this.props.ticket_id;
    const url = __env.TICKETS_API_URL + 'api/attachment?ticket_id=' + ticket_id + '&comment_id=' + comment_id + '&attachment_id='
      + id + '&filename=' + name + "/";
    this.props.sendRequest('get', url, {}, { "500": 0, "400": -1 }, true)
      .then(response => {
        const url = window.URL.createObjectURL(
          response.data
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          name,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        notify.error(t('attachment.warning_download_error'));
        getLogger().error('tickets-ui', new Error(t('attachment.warning_download_error')));
      });
  };

  render() {
    const name = this.props.name;
    return (
      <AttachmentButton onClick={this.getAttachment} fileName={name}/>
    );
  }
}


Attachment.propTypes = {
  t: PropTypes.func, //HOC
  name: PropTypes.string,
  id: PropTypes.string,
  comment_id: PropTypes.string,
  ticket_id: PropTypes.string,
  sendRequest: PropTypes.object,
  loginStore: PropTypes.object, //HOC
};




import * as React from 'react';
import PropTypes from 'prop-types';

import Comment from '../components/Comment';

export default function Comments(props) {
  const t = props.t;
  const comments = props.comments;
  if (comments.length > 0) {
    return (
      <div>
        <label htmlFor="comments"><b>{ t('edit_ticket_comments') }:</b></label>
        {comments.map((comment, index) =>
          <Comment body={comment['body']} author={ comment['author'] } created={ comment['created'] } id={ comment['id'] }
            attachments={ props.attachments } ticket_id={ String(props.ticket_id) } t={t} index={index} key={index}
          />
        )}
        <br/>
      </div>
    );
  }
  else {
    return null;
  }
}


Comments.propTypes = {
  comments: PropTypes.array,
  attachments: PropTypes.array,
  ticket_id: PropTypes.string,
  t: PropTypes.func,
};


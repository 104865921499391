import styled from 'styled-components';
import { Card } from 'reactstrap';
import picture from '../../../assets/svg/start/offers.svg';

export const StyledCard = styled(Card)`
  display: flex;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DCD6D6;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  padding: 0;
  margin: 0;
  gap: 51px;
  &:hover {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1), 8px 5px 14px rgba(196, 196, 196, 0.2);
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .icon {
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 90%;
    margin-bottom: 14%;
    background-image: url(${picture});
  }
  
  .picture {
    color: white;
    font-size: 1em;
    background-size: 100%;
    background-image: url(${picture});
  }

`;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import getCurrentLang from '../utils/contextUtils';
import { InstitutionLogoImage } from '../styles/styled-components/welcomePage';
import { __env } from '../../envloader';

@withTranslation()
export default class InstitutionLogo extends Component {
  render() {
    const { t } = this.props;
    const lang = getCurrentLang().substring(0, 2);
    const filename = `institution-logo-${lang}.svg`;
    const InstitutionLogoFromIbis = `${__env.IBIS_TENANT_URL}themes/${filename}`;
    // const InstitutionLogoFromIbis = `https://pracelab-ibis-devel.apps.paas-dev.psnc.pl/pracelab-devel/themes/${filename}`;
    return (
      <InstitutionLogoImage
        src={InstitutionLogoFromIbis}
        onError={e =>
          e.target.src = `/institution/${filename}`
        }
        alt={t('welcome.footer.logos.pracelab_institution')}
      />
    );
  }
}

InstitutionLogo.propTypes = {
  t: PropTypes.func, //HOC
};
import { toastr } from 'react-redux-toastr';

const toastrErrorOptions = {
  removeOnHover: false
};

export const error = (title, message) => {
  toastr.error(title, message, toastrErrorOptions);
};

const toastrSuccessOptions = {
  timeOut: 5000,
  removeOnHover: true
};

export const success = (title, message) => {
  toastr.success(title, message, toastrSuccessOptions);
};

const toastrWarningOptions = {
  removeOnHover: false
};

export const warning = (title, message) => {
  toastr.warning(title, message, toastrWarningOptions);
};

const toastrInfoOptions = {
  removeOnHover: false
};

export const info = (title, message) => {
  toastr.info(title, message, toastrInfoOptions);
};

import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

const theme = {
  colors: {
    primary: '#0FA1FF',
    secondary: '#106BEF',
    grey: '#676767',
    background: { 
      white: '#FFFFFF',
      lightGrey: '#F8F8F8'
    },
    text: {
      black: '#000000',
      darkGrey: '#333',
      grey1: '#676767',
      grey2: '#808080',
      grey3: '#979797',
      grey4: '#B7B7B7',
      grey5: '#D3D3D3'
    },
    alerts: {
      green: '#42033',
      red: '#D20724',
      orange: '#FFA216',
      blue: '#0FA1FF'
    }
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem', 
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xl2: '1.5rem',
    xl3: '1.875rem',
    xl4: '2rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.any.isRequired
};

export default Theme;
import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { StyledCard } from '../styles/styled-components/ContextCard';

@withTranslation()
export default class ContextCard extends Component {

  render() {
    const { t } = this.props;
    const tCard = 'welcome.' + this.props.card;
    let link = '/newTicket?context=' + this.props.card + '&edit=true';
    return (
      <Col className='grid-col'>
        <StyledCard>
          <div className="d-flex align-items-center justify-content-center main-image">
            <img className="picture" src={this.props.image} alt={t(tCard + '.image_alt')}/>
          </div>
          <div className='bottom-content'>
            <div className="d-flex align-items-center justify-content-center">
              <a className="btn btn-outline-secondary report-button" href={link}>
                {t(tCard + '.link_title')}
              </a>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <span>{t(tCard + '.description')}</span>
            </div>
          </div>
        </StyledCard>
      </Col>
    );
  }
}

ContextCard.propTypes = {
  image: PropTypes.string,
  card: PropTypes.string,
  t: PropTypes.func, //HOC
};

import styled from 'styled-components';

export const UserAvatarIcon = styled.span`
  height: 44px;
  width: 44px;
  background-color: #106BEF;
  border-radius: 50%;
  display: inline-block;
  border: none !important;
  outline: none !important;
  outline-width: 0;
  border-width: 0;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 14px;
`;


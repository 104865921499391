import React from 'react';

import { AxiosInstancesContext } from './utils/axiosContext';
import { sendRequest, sendAuthRequest } from './utils/axiosUtils';

// HOC, which add to Component function, which make requests using axios instance taken from context
export function withSendRequest(Component) {
  return function WrapperComponent(props) {
    return (
      <AxiosInstancesContext.Consumer>
        {instances => <Component {...props} sendRequest={sendRequest(instances)}
          sendAuthRequest={sendAuthRequest(instances)} />}
      </AxiosInstancesContext.Consumer>
    );
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import LandingPageFooter from '../components/LandingPageFooter';
import LandingPageHeader from '../components/LandingPageHeader';
import LandingPageCenter from '../components/LandingPageCenter';

@withTranslation()
export default class main extends Component {

  render() {
    return (
      <div>
        <LandingPageHeader/>
        <LandingPageCenter/>
        <LandingPageFooter/>
      </div>
    );
  }
}

main.propTypes = {
  t: PropTypes.func //HOC
};

import * as React from 'react';
import PropTypes from 'prop-types';

import problemIcon from '../../assets/svg/icons/type_problem.svg';
import questionIcon from '../../assets/svg/icons/type_question.svg';
import otherIcon from '../../assets/svg/icons/type_other.svg';
import { StyledTable, Td, Th } from '../styles/styled-components/TicketsList';
import dateIcon from '../../assets/svg/icons/date.svg';
import Status from './Status';
import editIcon from '../../assets/svg/icons/edit.svg';

function Ticket(props) {
  const ticket = props.ticket;
  const updated = new Date(ticket['updated']);
  const { t } = props;
  let ticketType = ticket['type'];
  let icon = problemIcon;
  if (ticketType === "Problem") {
    icon = problemIcon;
    ticketType = t('ticket_type_Problem');
  }
  if (ticketType === "Zapytanie") {
    icon = questionIcon;
    ticketType = t('ticket_type_Zapytanie');
  }
  if (ticketType === "Inne") {
    icon = otherIcon;
    ticketType = t('ticket_type_Inne');
  }
  return (
    <tr>
      <Td>
        <img src={icon} alt='problem icon' className='mr-2 w-15' />
        <span>{ ticketType }</span>
      </Td>
      <Td>{ticket['key']}</Td>
      <Td>{ticket['summary']}</Td>
      <Td>
        <img src={dateIcon} alt='updated icon' className='mr-2 w-15' />
        <span>{ updated.toLocaleString() }</span>
      </Td>
      <Td>
        <Status color={ ticket['status_color'] } status={ ticket['status'] }/>
      </Td>
      <Td>
        <div className="d-flex">
          <div className="p-2 mr-2">
            <button type="button" className="asText"
              onClick={() => {
                props.details(ticket['id']);
              }}
            ><span className="details">{t('ticket_list_view')}</span></button>
          </div>
          <div className="p-2">
            <button type="button" className="asText"
              onClick={() => {
                props.edit(ticket['id']);
              }}
            >
              <div className='flex-center'>
                <div className='flex-center'>
                  <img src={editIcon} alt='edit icon' className='mr-1 w-15' />
                  <span>{t('ticket_list_edit')}</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </Td>
    </tr>
  );
}

Ticket.propTypes = {
  ticket: PropTypes.object,
  t: PropTypes.func,
  details: PropTypes.func,
  edit: PropTypes.func,
};


export function Tickets(props) {
  const tickets = props.tickets;
  const { t } = props;
  if (tickets.length) {
    return (
      <StyledTable>
        <thead>
          <tr>
            <Th>{ t('ticket_type') }</Th>
            <Th>{ t('ticket_key') }</Th>
            <Th>{ t('ticket_summary') }</Th>
            <Th>{ t('ticket_list_updated') }</Th>
            <Th>{ t('ticket_status') }</Th>
            <Th>{ t('ticket_action') }</Th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) =>
            <Ticket key={ticket.id} ticket={ ticket } t={ props.t } details={ props.details } edit={props.edit} />
          )}
        </tbody>
      </StyledTable>
    );
  }
  else {
    return null;
  }
}

Tickets.propTypes = {
  tickets: PropTypes.array,
  t: PropTypes.func,
  details: PropTypes.func,
  edit: PropTypes.func,
};

export default Tickets;
